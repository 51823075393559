<template>
  <div class="client-list">
    <div class="header">
      <h1>My devices</h1>
      <button 
        v-if="!addMode" 
        @click="startAddDevice" 
        class="add-device-btn"
      >
        Add a device
      </button>
    </div>

    <form 
      v-if="addMode" 
      @submit.prevent="onSubmit" 
      class="add-device-form"
    >
      <div class="form-group">
        <label for="deviceName">Device Name</label>
        <input 
          id="deviceName"
          type="text" 
          v-model="newName" 
          placeholder="Enter device name" 
          required
          maxlength="50"
          ref="deviceNameInput"
        />
        <span 
          v-if="newName.length > 0" 
          class="char-count"
        >
          {{ newName.length }}/50
        </span>
      </div>
      <div class="form-actions">
        <button 
          type="submit" 
          class="save-btn"
          :disabled="!newName.trim()"
        >
          Save Device
        </button>
        <button 
          type="button" 
          @click="cancelAddDevice" 
          class="cancel-btn"
        >
          Cancel
        </button>
      </div>
    </form>

    <ul class="clients">
      <li class="client client-header">
        <span class="name">Device name</span>
      </li>
      <li v-for="client in clients" :key="client.id">
        <client-item
          :client="client"
          @clientNameEdited="editClientName(client.id, $event)"
          @publishRequested="publishMessage(client.id, $event)"
        ></client-item>
      </li>
    </ul>
  </div>
</template>

<style scoped>
.header {
  background-color: #1e9bed;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-device-btn {
  background-color: white;
  color: #1e9bed;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-device-btn:hover {
  background-color: #f0f0f0;
}

.add-device-form {
  background-color: #f4f4f4;
  padding: 16px;
  margin: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.form-group {
  margin-bottom: 16px;
  position: relative;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  color: #333;
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.char-count {
  position: absolute;
  right: 10px;
  top: 40px;
  font-size: 0.8em;
  color: #888;
}

.form-actions {
  display: flex;
  gap: 10px;
}

.save-btn, .cancel-btn {
  padding: 10px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.save-btn {
  background-color: #4CAF50;
  color: white;
}

.save-btn:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.cancel-btn {
  background-color: #f44336;
  color: white;
}

.client-list .clients {
  padding: 0;
  margin: 0;
}

.client-list .client-header {
  background-color: #c8f2ff;
  font-weight: bold;
}

.client-list .clients li {
  list-style: none;
  font-size: 0.9em;
}

.client-list .client {
  text-align: left;
  border-top: 1px dotted #bbb;
  padding: 16px;
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
}
</style>

<script>
import ClientItem from './ClientItem.vue';
import { CLIENTS_ADD, CLIENTS_EDIT, CLIENTS_REQUEST, PUBLISH_MESSAGE } from '../store/mutation-types';

export default {
  components: {
    ClientItem
  },
  computed: {
    clients () {
      return this.$store.state.clients
    }
  },
  data() {
    return {
      addMode: false,
      newName: ''
    }
  },
  mounted () {
    this.$store.dispatch(CLIENTS_REQUEST)
  },
  methods: {
    startAddDevice() {
      this.addMode = true;
      this.$nextTick(() => {
        this.$refs.deviceNameInput.focus();
      });
    },
    cancelAddDevice() {
      this.addMode = false;
      this.newName = '';
    },
    editClientName(clientId, newName) {
      this.$store.dispatch(CLIENTS_EDIT, { id: clientId, name: newName });
    },
    publishMessage(clientId, event) {
      this.$store.dispatch(PUBLISH_MESSAGE, { id: clientId, payload: event });
    },
    onSubmit() {
      // Trim the name and validate
      const trimmedName = this.newName.trim();
      if (!trimmedName) return;

      // Dispatch add action
      this.$store.dispatch(CLIENTS_ADD, { name: trimmedName });
      
      // Reset form
      this.newName = '';
      this.addMode = false;
    }
  }
};
</script>
