<template>
    <div class="client">
        <span class="name" v-if="!editMode">{{ client.name }}</span>
        <input v-else type="text" v-model="newName"/>

        <span v-if="!client.connected" class="connection-status">OFFLINE</span>
        <span v-if="client.connected" class="connection-status connected">ONLINE</span>

        <div class="controls" :class="{ 'controls-disabled': controlsDisabled }">
            <div v-if="deviceState === 'on'" class="turn-off-controls">
                <!-- Simple off button when device is on -->
                <button 
                    @click="publishOff()"
                    class="control-button turn-off"
                    :disabled="controlsDisabled"
                >
                    <span v-if="isLoading" class="loading-indicator"></span>
                    Turn Off
                </button>
            </div>

            <div v-else class="turn-on-controls">
                <!-- Quick timer buttons when device is off -->
                <div class="timer-presets">
                    <button 
                        v-for="preset in timePresets" 
                        :key="preset.value"
                        @click="publishOnWithTimer(preset.value)"
                        class="preset-button turn-on"
                        :disabled="controlsDisabled"
                    >
                        <span v-if="isLoading" class="loading-indicator"></span>
                        Turn on for {{ preset.label }}
                    </button>
                    <button 
                        @click="publishOnWithTimer(0)"
                        class="preset-button turn-on"
                        :disabled="controlsDisabled"
                    >
                        <span v-if="isLoading" class="loading-indicator"></span>
                        Turn on
                    </button>
                </div>
            </div>
        </div>

        <div v-if="client.status" class="status">
            <span class="item">
                Status:
                <span class="material-icons-outlined power-icon" :class="deviceState">
                    power_settings_new
                </span>
            </span>
            <button 
                @click="refreshState()"
                class="refresh-button"
                :disabled="controlsDisabled"
                title="Refresh state"
            >
                <span class="material-icons-outlined">refresh</span>
            </button>
        </div>
    </div>
</template>

<style scoped>
.name {
    height: 2em;
    line-height: 2em;
}

.connection-status {
    color: red;
    height: 2em;
    line-height: 2em;
    font-size: 0.8em;
}

.connection-status.connected {
    color: #006400;
}

.status {
    width: 100%;
    font-size: 1rem;
    display: flex;
    align-items: center;
    gap: 8px;
}

.status .item {
    padding-right: 8px;
    display: flex;
    align-items: center;
    gap: 4px;
}

.status .power-icon {
    font-size: 1.4rem;
}

.status .power-icon.on {
    color: #4CAF50;
}

.status .power-icon.off {
    color: #dc3545;
}

.refresh-button {
    background: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 4px 8px;
    cursor: pointer;
    font-size: 14px;
    position: relative;
    margin-left: auto;
}

.refresh-button:hover {
    background: #f0f0f0;
}

.refresh-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.controls {
    margin: 10px 0;
    width: 100%;
}

.controls-disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
}

.timer-presets {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
    width: 100%;
    max-width: 400px;
}

.preset-button {
    padding: 12px;
    border-radius: 8px;
    font-size: 1rem;
    touch-action: manipulation;
    cursor: pointer;
    width: 100%;
    position: relative;
}

.preset-button.turn-on {
    background-color: #4CAF50;
    color: white;
    border: none;
}

.preset-button.turn-on:active {
    background-color: #3d8b40;
}

.turn-off {
    background-color: #dc3545;
    color: white;
    border: none;
}

.control-button {
    padding: 12px 24px;
    border-radius: 8px;
    font-size: 1rem;
    touch-action: manipulation;
    cursor: pointer;
    width: 100%;
    max-width: 400px;
    position: relative;
}

.preset-button:disabled, 
.control-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

/* Loading indicator styles */
.loading-indicator {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
}

.loading-indicator::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.8);
    animation: pulse 1s infinite ease-in-out;
}

@keyframes pulse {
    0% {
        transform: scale(0.6);
        opacity: 0.4;
    }
    50% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        transform: scale(0.6);
        opacity: 0.4;
    }
}

/* Make buttons easier to tap on mobile */
@media (max-width: 768px) {
    .preset-button, .control-button {
        min-height: 48px;
    }
}
</style>

<script>
export default {
    props: ['client'],
    data() {
        return {
            editMode: false,
            newName: this.client.name,
            timePresets: [
                { label: '15m', value: 15 },
                { label: '30m', value: 30 },
                { label: '1h', value: 60 },
            ],
            isLoading: false,
            loadingTimeout: null
        }
    },
    computed: {
        deviceState() {
            // Handle both old and new status format
            if (this.client.status?.estado) {
                return this.client.status.estado;
            }
            return this.client.status?.state || 'off';
        },
        controlsDisabled() {
            return !this.client.connected || this.isLoading;
        },
        lastStateChange() {
            return this.$store.state.clientStateChanges[this.client.id] || 0;
        }
    },
    watch: {
        lastStateChange: {
            handler(newVal) {
                if (newVal > 0) {
                    // Clear loading state when we detect a state change
                    this.isLoading = false;
                    if (this.loadingTimeout) {
                        clearTimeout(this.loadingTimeout);
                        this.loadingTimeout = null;
                    }
                }
            }
        }
    },
    methods: {
        toggleEditMode() {
            this.newName = this.client.name;
            this.editMode = !this.editMode;
        },
        save() {
            this.editMode = !this.editMode;
            if (this.newName !== this.client.name) {
                this.$emit('clientNameEdited', this.newName);
            }
        },
        startLoading() {
            this.isLoading = true;
            // Reset any existing timeout
            if (this.loadingTimeout) {
                clearTimeout(this.loadingTimeout);
            }
            // Set timeout to clear loading state after 2 minutes
            this.loadingTimeout = setTimeout(() => {
                this.isLoading = false;
            }, 120000);
        },
        publishOnWithTimer(minutes) {
            this.startLoading();
            this.publish(minutes, 'setState:on');
        },
        publishOff() {
            this.startLoading();
            this.publish(0, 'setState:off');
        },
        publish(timerMinutes, state) {
            let payload = {
                timerMinutes,
                payload: state
            };
            this.$emit('publishRequested', payload);
        },
        refreshState() {
            this.$emit('publishRequested', { payload: 'getState' });
        }
    },
    beforeUnmount() {
        // Clean up timeout if component is destroyed
        if (this.loadingTimeout) {
            clearTimeout(this.loadingTimeout);
        }
    }
};
</script>
